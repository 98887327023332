//
// Typography
//
// stylelint-disable primer/selector-no-utility, primer/no-override, selector-no-type, selector-max-type

h1,
.text-alpha {
  @include fs-8;
  font-weight: 300;
}

h2,
.text-beta {
  @include fs-6;
}

h3,
.text-gamma {
  @include fs-5;
}

h4,
.text-delta {
  @include fs-2;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

h4 code {
  text-transform: none;
}

h5,
.text-epsilon {
  @include fs-3;
  color: $grey-dk-200;
}

h6,
.text-zeta {
  @include fs-2;
  color: $grey-dk-200;
}

.text-small {
  @include fs-2;
}

.text-mono {
  font-family: $mono-font-family !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

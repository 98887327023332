//
// Typography
//

$body-font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, sans-serif !default;
$mono-font-family: "SFMono-Regular", Menlo, Consolas, Monospace !default;
$root-font-size: 16px !default; // Base font-size for rems
$body-line-height: 1.4 !default;
$content-line-height: 1.6 !default;
$body-heading-line-height: 1.25 !default;

//
// Font size
// `-sm` suffix is the size at the small (and above) media query
//

$font-size-1: 9px !default;
$font-size-1-sm: 10px !default;
$font-size-2: 11px !default; //h4 - uppercased!, h6 not uppercased, text-small
$font-size-3: 12px !default; //h5
$font-size-4: 14px !default;
$font-size-5: 16px !default; //h3
$font-size-6: 18px !default; //h2
$font-size-7: 24px !default;
$font-size-8: 32px !default; //h1
$font-size-9: 36px !default;
$font-size-10: 42px !default;
$font-size-10-sm: 48px !default;

//
// Colors
//

$white: #fff !default;

$grey-dk-000: #959396 !default;
$grey-dk-100: #5c5962 !default;
$grey-dk-200: #44434d !default;
$grey-dk-250: #302d36 !default;
$grey-dk-300: #27262b !default;

$grey-lt-000: #f5f6fa !default;
$grey-lt-100: #eeebee !default;
$grey-lt-200: #ecebed !default;
$grey-lt-300: #e6e1e8 !default;

$purple-000: #7253ed !default;
$purple-100: #5e41d0 !default;
$purple-200: #4e26af !default;
$purple-300: #381885 !default;

$blue-000: #2c84fa !default;
$blue-100: #2869e6 !default;
$blue-200: #264caf !default;
$blue-300: #183385 !default;

$green-000: #41d693 !default;
$green-100: #11b584 !default;
$green-200: #009c7b !default;
$green-300: #026e57 !default;

$yellow-000: #ffeb82 !default;
$yellow-100: #fadf50 !default;
$yellow-200: #f7d12e !default;
$yellow-300: #e7af06 !default;

$red-000: #f77e7e !default;
$red-100: #f96e65 !default;
$red-200: #e94c4c !default;
$red-300: #dd2e2e !default;

$body-background-color: $white !default;
$sidebar-color: $grey-lt-000 !default;
$search-background-color: $white !default;
$table-background-color: $white !default;
$code-background-color: $grey-lt-000 !default;
$feedback-color: darken($sidebar-color, 3%) !default;

$body-text-color: $grey-dk-100 !default;
$body-heading-color: $grey-dk-300 !default;
$search-result-preview-color: $grey-dk-000 !default;
$nav-child-link-color: $grey-dk-100 !default;
$link-color: $purple-000 !default;
$btn-primary-color: $purple-100 !default;
$base-button-color: #f7f7f7 !default;

//
// Spacing
//

$spacing-unit: 1rem; // 1rem == 16px

$spacers: (
  sp-0: 0,
  sp-1: $spacing-unit * 0.25,
  sp-2: $spacing-unit * 0.5,
  sp-3: $spacing-unit * 0.75,
  sp-4: $spacing-unit,
  sp-5: $spacing-unit * 1.5,
  sp-6: $spacing-unit * 2,
  sp-7: $spacing-unit * 2.5,
  sp-8: $spacing-unit * 3,
  sp-9: $spacing-unit * 3.5,
  sp-10: $spacing-unit * 4,
) !default;

$sp-1: map-get($spacers, sp-1) !default; // 0.25 rem == 4px
$sp-2: map-get($spacers, sp-2) !default; // 0.5  rem == 8px
$sp-3: map-get($spacers, sp-3) !default; // 0.75 rem == 12px
$sp-4: map-get($spacers, sp-4) !default; // 1    rem == 16px
$sp-5: map-get($spacers, sp-5) !default; // 1.5  rem == 24px
$sp-6: map-get($spacers, sp-6) !default; // 2    rem == 32px
$sp-7: map-get($spacers, sp-7) !default; // 2.5  rem == 40px
$sp-8: map-get($spacers, sp-8) !default; // 3    rem == 48px
$sp-9: map-get($spacers, sp-9) !default; // 3.5  rem == 56px
$sp-10: map-get($spacers, sp-10) !default; // 4  rem == 64px

//
// Borders
//

$border: 1px solid !default;
$border-radius: 4px !default;
$border-color: $grey-lt-100 !default;

//
// Grid system
//

$gutter-spacing: $sp-6 !default;
$gutter-spacing-sm: $sp-4 !default;
$nav-width: 264px !default;
$nav-width-md: 248px !default;
$nav-list-item-height: $sp-6 !default;
$nav-list-item-height-sm: $sp-8 !default;
$nav-list-expander-right: true;
$content-width: 800px !default;
$header-height: 60px !default;
$search-results-width: $content-width - $nav-width !default;
$transition-duration: 400ms;

//
// Media queries in pixels
//

$media-queries: (
  xs: 320px,
  sm: 500px,
  md: $content-width,
  lg: $content-width + $nav-width,
  xl: 1400px,
) !default;
